<template>
  <MasterLayout backgroundColor="bg-cq-orange">
    <!-- Old Quests from PlayFab
    <div class="questListFlexbox">
      <div class="questList">
        <QuestCard v-for="quest in $store.state.events" v-bind:key="quest.id" v-bind:quest="quest"/>
      </div>
    </div> -->

    <!-- *NEW* Database Quests -->
    <!-- loading skeleton -->
    <div v-if="loading" class="flex flex-col items-center justify-center mt-12">
      <Skeleton width="20%" height="3rem" borderRadius="5px" class="mb-2" />
      <Skeleton width="10%" height="1rem" borderRadius="5px" class="mb-4" />
      <Skeleton width="95%" height="20rem" borderRadius="5px" />
    </div>
    <!-- 1 or More -->
    <template v-else-if="databaseQuests && databaseQuests.quests.length > 0">
      <p class="text-center font-black text-4xl my-20">
        {{ databaseQuests.quests[0].provider.name }}
      </p>
      <p class="text-center text-xl my-20">
        {{ databaseQuests.quests[0].provider.mission }}
      </p>
      <!-- <h2 class="max-w-lg mx-auto text-center" >We have {{ databaseQuests.quests.length }} Database Quests!</h2> -->

      <!-- THE NEW WAY, SIFTED DATE ARRAY -->
      <div class="max-w-lg mx-auto text-xl text-white space-y-6 pb-10">
        <div v-for="(quests, date) in theSiftedQuestArray" :key="date">
          <!-- Formatted Date -->
          <h3>{{ formatDate(date) }}</h3>
          <!-- The Quests for Each Date Bucket -->
          <div class="max-w-lg mx-auto space-y-6 pb-10">
            <DBQuestCard
              v-for="quest in quests"
              v-bind:key="quest.id"
              v-bind:quest="quest"
            />
          </div>
        </div>
      </div>

      <!-- THE OLD WAY, A BIG LINEAR ARRAY
      <div class="max-w-lg mx-auto space-y-6 pb-10">
        <DBQuestCard
          v-for="quest in databaseQuests.quests"
          v-bind:key="quest.id"
          v-bind:quest="quest"
        />
      </div> -->
    </template>
    <!-- Zero -->
    <template v-else>
      <br>
      <p class="max-w-lg mx-auto text-center pb-10">
        This charity does not currently have any more Quests scheduled.<br />Please
        check back toward the end of the month for new Quests!
      </p>
      <br>
      <p class="max-w-lg mx-auto text-center pb-10">Administrators, if you don't see your Quests here, go to the Calendar and double-check that they are set to "Published" rather than "Drafts"!</p>
    </template>

    <!-- <script type="text/javascript">var rnd = window.rnd || Math.floor(Math.random()*10e6);var pid687794 = window.pid687794 || rnd;var plc687794 = window.plc687794 || 0;var abkw = window.abkw || '';var absrc = 'https://ads.empowerlocal.co/adserve/;ID=181918;size=0x0;setID=687794;type=js;sw='+screen.width+';sh='+screen.height+';spr='+window.devicePixelRatio+';kw='+abkw+';pid='+pid687794+';place='+(plc687794++)+';rnd='+rnd+';click=CLICK_MACRO_PLACEHOLDER';var _absrc = absrc.split("type=js"); absrc = _absrc[0] + 'type=js;referrer=' + encodeURIComponent(document.location.href) + _absrc[1];document.write('<scr'+'ipt src="'+absrc+'" type="text/javascript"></'+'script>');</script> -->
  </MasterLayout>
</template>

<script>
// @ is an alias to /src
import DBQuestCard from "@/components/DBQuestCard.vue";
//import QuestCard from "@/components/QuestCard.vue";
import MasterLayout from "@/components/MasterLayout.vue";
import { useQuery } from "@vue/apollo-composable";
import { useRoute } from "vue-router";
import gql from "graphql-tag";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Skeleton from "primevue/skeleton";

// QuestList should be a *specific* charity's QuestCards
// Currently it's everything.

export default {
  name: "QuestList",
  data() {
    return {
      databaseQuestz: []
    };
  },
  components: {
    DBQuestCard,
    Skeleton,
    //QuestCard,
    MasterLayout
  },
  props: ["providerID"],
  watch: {
    providerID: function(newVal, oldVal) {
      this.refetch({
        isUpcoming: true,
        isPublished: true,
        specificProvider: newVal
      });
    }
  },
  setup() {
    const notifyError = text => {
      toast.error(text, {
        autoClose: false
      }); // ToastOptions
    };

    const notifySuccess = text => {
      toast.success(text, {
        autoClose: 10000
      }); // ToastOptions
    };

    const route = useRoute();
    const { result, loading, error, refetch } = useQuery(
      gql`
        query GetUpcomingQuests(
          $isUpcoming: Boolean!
          $isPublished: Boolean!
          $specificProvider: String!
        ) {
          quests(
            isUpcoming: $isUpcoming
            isPublished: $isPublished
            specificProvider: $specificProvider
          ) {
            id
            providerId
            provider {
              name
              mission
            }
            eventTitle
            eventType
            description
            serviceTags
            startedAt
            endedAt
            flexibleMinimumTime
            locationId
            location {
              id
              name
              address
              detail
              latitude
              longitude
            }
            resourceMinNumber
            resourceDesiredNumber
            resourceMaxNumber
            pledgedContracts {
              id
              chariteerId
              chariteer {
                username
                nameFirst
              }
              claimedAt
              abandonedAt
              guild {
                name
              }
              guildId
            }
          }
        }
      `,
      {
        isUpcoming: true,
        isPublished: true,
        specificProvider: route.params.providerID
      }
    );
    console.log("Refetching Quests!");
    console.log(result);
    // console.log("Our route params is " + route.params.providerID);
    // console.log("Can we see the Quests we refetched?");
    // console.log(result.quests); <== This is undefined!
    // console.log(error);

    return {
      databaseQuests: result || null,
      loading,
      error,
      refetch,
      id: route.params.providerID,
      notifyError,
      notifySuccess
    };
  },
  created() {
    console.log("Legacy events have been toggled off.");
    //console.log("About to fetch legacy events for " + this.providerID);
    //this.$store.dispatch('fetchEvents', this.providerID)
  },
  methods: {
    formatDate( dateString ) {
      if (dateString === 'null')
        return 'Roles & Donation Links'; // Handle 'null' bucket

      // Displayed the wrong date due to time zone issues
      // const date = new Date(dateString);
      // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      // return date.toLocaleDateString('en-US', options).replace(',', ''); // Format the date

      // Split by '-' to avoid time zone issues
      const [year, month, day] = dateString.split('-');
      const date = new Date(year, month - 1, day); // month is zero-indexed

      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString('en-US', options); // Format the date
    }
  },
  computed: {
    theSiftedQuestArray() {
      // Let's take the On-Site Volunteer quests and put them into Date buckets.
      // Other things like Training, Donation Links, and Hybrid Roles can go into their own
      // non-date buckets. Possibly a different array too?

      // Let's group these by date quickly:
      const groupBy = (array, key) => {
        return array.reduce((result, item) => {
          const groupKey = item[key] ? item[key].split('T')[0] : 'null'; // the null bucket can be sorted later
          if (!result[groupKey]) {
            result[groupKey] = [];
          }
          result[groupKey].push(item);
          return result;
        }, {});
      };

      const groupedQuests = groupBy(this.databaseQuests.quests, 'startedAt');
      console.log("Sorted array:");
      console.log(groupedQuests);

      // return siftVolunteerContracts(this.databaseQuest.quest.pledgedContracts);
      return groupedQuests;
    }
  }
};
</script>

<style scoped>
h1 {
  color: white;
  margin-top: 100px;
}

p {
  color: white;
}

.questList {
  padding-top: 0px;
}

.questListFlexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px auto; /* Set the app to be centered */
  padding-top: 10px;
  padding-bottom: 10px; /* This is for the footer */
}

#placement_687794_0_i {
  width: 100%;
  margin: 0 auto;
}
</style>
