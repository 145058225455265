<template>
  <div id="page-container">
    <TopNav v-show="true"/>
    <div id="content-wrap">
      <!-- Probably need a little toolbar box to contain buttons in -->
      <div class="w-1/3 mx-auto py-5">
        <template v-if="$store.state.currentlyViewedQuest.eventType == 'supplyRequest'">
          <p>Supply Requests cannot be edited yet.</p>
        </template>

        <template v-else>
          <button class="editQuestButton" v-on:click="openEditQuestModal">
            Edit this Quest's details
          </button>
        </template>
      </div>
      

      <div class="questDetails">
        <h1>{{ $store.state.currentlyViewedQuest.eventTitle }}</h1>
        
        <!-- Here, I would really like to mention the name of the charity! -->
        <h2>{{ $store.state.currentlyViewedQuest.providerName }}</h2>

        <!-- DATE & TIME -->
        <template v-if="$store.state.currentlyViewedQuest.endTime != undefined">
          <p>{{ new Date($store.state.currentlyViewedQuest.date).toDateString() + " from " + $store.state.currentlyViewedQuest.startTime + " to " + $store.state.currentlyViewedQuest.endTime }}</p>
        </template>
        <template v-else>
          <p>{{ new Date($store.state.currentlyViewedQuest.date).toDateString() + " at " + $store.state.currentlyViewedQuest.startTime }}</p>
        </template>
        
        <br>

        <!--
        <h4>Mission:</h4>
        <div class="special-class" markdown="1">
          {{ $store.state.currentlyViewedQuest.description1 + " " + $store.state.currentlyViewedQuest.description2 + " " + $store.state.currentlyViewedQuest.description3 }}
        </div>
        <br>
        -->

        <!-- *NEW* Quest Description that allows for Markdown -->
        <h4>Description:</h4>
        <div v-html="questDescription1" class="questDescription"></div>
        <div v-html="questDescription2" class="questDescription"></div>
        <div v-html="questDescription3" class="questDescription"></div>
        <br>

        <h4>Location:</h4>
        <p>{{ $store.state.currentlyViewedQuest.locationName }}</p>
        <p>{{ $store.state.currentlyViewedQuest.locationAddress }}</p>
        <p>{{ $store.state.currentlyViewedQuest.locationDetail }}</p>
        <br>

        <div class="questDetailsFlexbox">

          <!-- GROUP, IF ONE HAS CLAIMED THIS -->
          <!-- = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = -->
          <div class="questDetailsGroupBox" v-if="isRaided == true">
            <p class="questDetailsGroupTitle">GROUP</p>
            <p class="questDetailsGroupName">{{ $store.state.currentlyViewedQuest.raidInfo.raidingGroupName }}</p>
          </div>

          <!-- VOLUNTEERS -->
          <!-- = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = -->
          <ul class="questDetailsVolunteerList">
            <h3>Volunteers {{ "( " + $store.state.currentlyViewedQuest.volunteerInfo.length + " of " + $store.state.currentlyViewedQuest.volunteerMax + " )" }}</h3>
          
            <!-- WORK HERE -->
            <li class="questDetailsVolunteerListOther" v-for="item in $store.state.currentlyViewedQuest.volunteerInfo" :key=item.userID>
              <div class="attendance-leftSide">
                {{ item.firstName + " " + item.lastName + " (" + item.age + ")" }}
                <br>
                {{ item.emailAddress }}
                <br>
                <!-- Taking it on faith that these are 10 digit numbers here -->
                <template v-if="item.phoneNumber != null">
                  {{ "(" + item.phoneNumber.substring(0, 3) + ") " + item.phoneNumber.substring(3, 6) + "-" + item.phoneNumber.substring(6, 10) }}
                </template>
              </div>

              <div class="attendance-rightSide">
                <!-- Checkmark for PRESENT -->
                <button class="attendanceButton" v-on:click="markAttendance(item, 'Present')">
                  <img class="attendanceButtonImage" v-if="ready == true && checkAttendance(item, 'Present')" alt="A checkmark to show this volunteer was marked present." src="../assets/CQ-Butticon_Present.png"/>
                  <img class="attendanceButtonImage" v-else alt="A grey checkmark to show this volunteer wasn't marked present." src="../assets/CQ-Butticon_Present_Grey.png"/>
                </button>

                <!-- X for ABSENT -->
                <button class="attendanceButton" v-on:click="markAttendance(item, 'Absent')">
                  <img class="attendanceButtonImage" v-if="checkAttendance(item, 'Absent')" alt="An X to show this volunteer was marked absent." src="../assets/CQ-Butticon_Absent.png"/>
                  <img class="attendanceButtonImage" v-else alt="A grey X to show this volunteer wasn't marked absent." src="../assets/CQ-Butticon_Absent_Grey.png"/>
                </button>

                <!-- ? for UNMARKED -->
                <!-- Removed because it was confusing I think
                <button class="attendanceButton">
                  <img class="attendanceButtonImage" v-if="checkAttendance(item, 'Upcoming')" alt="A question mark to show that the charity hasn't marked this yet." src="../assets/CQ-Butticon_Unknown.png"/>
                  <img class="attendanceButtonImage" v-else alt="A question mark to show that the charity has marked this volunteer as present or absent." src="../assets/CQ-Butticon_Unknown_Grey.png"/>
                </button>
                -->
              </div>
            </li>
            <!-- WORK HERE-->













            <li class="questDetailsVolunteerListEmpty" v-for="n in slotsRemaining" v-bind:key=n>
              Open Slot
            </li>
          </ul>

          <!-- SURVEYS -->
          <!-- If we have any surveys & results attached to this Quest, let's display that here: -->
          <template v-if="$store.state.currentlyViewedQuest.survey != undefined && combinedSurveyResultAndQuestions != null">
            <div class="questDetailsSurvey">
              <!-- We're using a computed property to merge the Survey and Results -->
              <h3>{{ combinedSurveyResultAndQuestions.surveyTitle }}</h3>
              <template v-if="$store.state.currentlyViewedQuest.surveyResult != undefined && $store.state.currentlyViewedQuest.surveyResult.answers">
                <ul>
                  <li class="questDetailsSurveyAnswer" v-for="question in combinedSurveyResultAndQuestions.questions" :key=question.questionID>
                    <b>{{ question.question }}</b>
                    <br>
                    {{ "\“" + question.answer + "\"" }}
                  </li>
                </ul>
              </template>
            </div>
          </template>
        
          <!-- THE SAVE BUTTON -->
          <!-- This can be "greyed out" if we have no changes. This means our data matches what we downloaded, no difference. -->
          <button class="questDetails-saveAttendanceButton" v-bind:class="{ disabledSaveButtonStyle: providerHasSaveChanges == false }" v-on:click="saveAttendanceRecordsToCloud">
            Confirm and E-Verify Volunteer Attendance
          </button>
        </div>

      </div>
    </div>
    <Footer/>

    <!-- = = = = = = = = = = = MODALS = = = = = = = = = = = -->
    <!-- Edit Quest (Modal) -->
    <div class="edit-quest-modal" v-if="editQuestModalIsVisible">
      <!-- Modal content -->
      <div class="edit-quest-modal-content">

        <!-- Top Buttons -->
        <div class="modal-top-button-row"> 
          <button class="modal-close" v-on:click="closeEditQuestModal">×</button>
        </div>

        <!-- Basic Info -->
        <p class="text-center font-bold text-4xl">Edit “{{ $store.state.currentlyViewedQuest.eventTitle }}"</p>
          
            <p class="text-center">Maximum Number of Volunteers:</p>
          <div class="grid grid-cols-3 gap-1 w-1/2 mx-auto">
            <input type="number" v-model="editableMaximumNumberOfVolunteers" class="" @keypress="checkKey" placeholder="" />
            <button v-on:click="decreaseEditedVolunteerMax" class="rounded-full border-0 bg-gray-800 text-white">-</button>
            <button v-on:click="increaseEditedVolunteerMax" class="rounded-full border-0 bg-gray-800 text-white">+</button>
          </div>
        <br>

        <!-- Edit Quest Button -->
        <div class="grid grid-cols-1 place-content-center mx-20">
          <button v-on:click="saveQuestEditsToCloud" class="rounded-full border-0 bg-green-400 w-full py-2">Save Changes</button>
        </div>

      </div>
    </div>

    
    <!-- Loading Quest Changes... (Modal) -->
    <div class="successful-changes-modal" v-if="$store.state.questDetailsChangesAreLoading">
      <!-- Modal content -->
      <div class="successful-changes-modal-content">

        <!-- Top Buttons -->
        <div class="modal-top-button-row"> 
          <button class="modal-close">×</button>
        </div>

        <!-- Basic Info -->
        <h1>Loading changes to “{{ $store.state.currentlyViewedQuest.eventTitle }}" . . .</h1>
        <p>Please wait...</p>
        <br>
      </div>
    </div>


    <!-- Changes Successfully Made (Modal) -->
    <div class="successful-changes-modal" v-if="$store.state.questDetailsChangesSubmittedSuccessfully">
      <!-- Modal content -->
      <div class="successful-changes-modal-content">

        <!-- Top Buttons -->
        <div class="modal-top-button-row"> 
          <button class="modal-close" v-on:click="closeSuccessfulChangesModal">×</button>
        </div>

        <!-- Basic Info -->
        <h1>You changed “{{ $store.state.currentlyViewedQuest.eventTitle }}" !</h1>
        <p>The new maximum number of volunteers allowed for this Quest is {{ $store.state.currentlyViewedQuest.volunteerMax }}. </p>
        <br>
      </div>
    </div>

    <!-- = = = = = = = = = = = = = = = = END OF PAGE CONTAINER = = = = = = = = = = = = = = = = -->
  </div>
</template>

<script>
// QuestDetails shows you everything about the Quest.
// Intended to be accessed via QuestList OR your ActiveQuests.
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "QuestDetails",
  components: {
    TopNav,
    Footer
  },
  props: ['id'],
  data() {
    return {
      chariteerAttendanceArray: [],
      editQuestModalIsVisible: false,
      editableMaximumNumberOfVolunteers: 0,
      isValidEditableVolunteerMax: false,
      successfulChangesModalIsVisible: true,
      ready: false
    }
  },
  created() {
    console.log("Fetching an event for " + this.id)
    this.$store.dispatch('fetchEvent', this.id)

    // this.printPlayerInventory()

    // This may be a good time to take the data from the Internet about the Actual Attendance of our Volunteers and store it in Data for use later
    // Later happens pretty soon because the part where it shows volunteers needs to be preset with this stuff
    // so data {} needs some kind of array of key value pairs like { id: BFFID123BBQ711 actualAttendance: Absent } and that determines how the buttons look when you first pull this up.
    // you can obviously edit that client side, which is then saved in data ("don't hit the back button!") and can be actually saved if you hit the Save button.
  
    var i = 0
    var newCopyOfActualAttendance

    for ( i; i < this.$store.state.currentlyViewedQuest.volunteerInfo.length; i++ )
    {
      newCopyOfActualAttendance = {}

      newCopyOfActualAttendance.userID = this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID
      newCopyOfActualAttendance.markedAttendance = this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance

      this.chariteerAttendanceArray.push(newCopyOfActualAttendance)
    }

    console.log(this.chariteerAttendanceArray)
    this.ready = true
  },
  methods: {
    checkAttendance(item, checkString)
    {
      // console.log("The length of the array is " + this.chariteerAttendanceArray.length + ". Assessing if " + item.userID + " is " + checkString + "...")

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        // console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].userID == item.userID )
        {
          // console.log(this.chariteerAttendanceArray[i].userID + " is " + item.userID + "!")
          
          // Found it. Now to check the string.
          if ( this.chariteerAttendanceArray[i].markedAttendance == checkString )
          {
            // console.log(this.chariteerAttendanceArray[i].markedAttendance + " is " + checkString)
            return true
          }
          else
          {
            // console.log(this.chariteerAttendanceArray[i].markedAttendance + " is NOT " + checkString)
            return false
          }
        }
      }

      // If we can't find the ID, which would be... odd... return false?
      return false
    },
    markAttendance(item, newMarkedAttendance)
    {
      console.log("Let's mark " + item.firstName + " " + item.lastName + " " + newMarkedAttendance + ".")

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        // console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].userID == item.userID )
        {
          // console.log(this.chariteerAttendanceArray[i].userID + " is " + item.userID + "!")
          
          // Found it. Now to change the value
          this.chariteerAttendanceArray[i].markedAttendance = newMarkedAttendance
          
          return
        }
      }
      
      return
    },
    saveAttendanceRecordsToCloud() {
      // I think the way this is structured I need to send an entire Quest, not just volunteerInfo, over there.
      // But at least right now I don't need to have a "Ghost Quest" in my data, just the ChariteerAttendanceArray.
      // So I'll make a copy of this Quest here, and make sure it has this new marked info which is verified to be new (or else the button wouldn't have worked), and send that
      console.log("Initiate save action")

      // Let's make a new deep copy of currentlyViewedQuest
      let ghostQuest = JSON.parse(JSON.stringify(this.$store.state.currentlyViewedQuest));

      // TODO: make sure this doesn't glitch if the staffer is taking attendance and someone bails from the Quest
      // The attendance in ghostQuest must be changed to reflect what the staffer is marking
      var i = 0
      for ( i; i < ghostQuest.volunteerInfo.length; i++ )
      {
        console.log("Loop " + i)
        console.log(this.$store.state.currentlyViewedQuest.volunteerInfo[i].firstName + " was " + this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance + ", and is now " + this.chariteerAttendanceArray[i].markedAttendance)
        ghostQuest.volunteerInfo[i].actualAttendance = this.chariteerAttendanceArray[i].markedAttendance
      }

      // console.log("Ghost Quest:")
      // console.log(ghostQuest)
      // console.log("Currently Viewed Quest:")
      // console.log(this.$store.state.currentlyViewedQuest)

      // Wipe out uneeded values to conserve storage space (NOT NECESSARY BECAUSE THE CLOUDSCRIPT IGNORES THEM ANYWAY)

      // PRIORITY #1: Changing the attendance values in the Quest itself
      // Dispatch this action and bring ghostQuest along
      this.$store.dispatch('providerAlterSpecificQuestAttendance', ghostQuest)

      // PRIORITY #2: Rewarding or punishing each Chariteer individually, so we can handle infinite volunteers inside one Quest
      // It had to be this way because of the limit imposed by PlayFab on API calls per Cloudscript Execution
      for ( i = 0; i < ghostQuest.volunteerInfo.length; i++ )
      {
        // this may not work
        var ghostQuestBundle = ghostQuest
        ghostQuestBundle.chariteerArrayIndex = i
        this.$store.dispatch('providerVerifyChariteerQuestContract', ghostQuestBundle)
      }
    },
    openEditQuestModal() {
      console.log("Editing a Quest:")
      console.log("Current Max Volunteers is " + this.$store.state.currentlyViewedQuest.volunteerMax)
      this.editQuestModalIsVisible = true;
      this.editableMaximumNumberOfVolunteers = this.$store.state.currentlyViewedQuest.volunteerMax;
    },
    closeEditQuestModal () {
      console.log("Goodbye!")
      // wipe out the edits?
      this.editQuestModalIsVisible = false;
    },
    checkKey(event) {
      if ( event.key === '-' || event.key === 'e' )
        event.preventDefault()
    },
    increaseEditedVolunteerMax () {
      // any chance we're not dealing with a number here?

      this.editableMaximumNumberOfVolunteers++
    },
    decreaseEditedVolunteerMax () {
      // any chance we're not dealing with a number here?

      if (this.editableMaximumNumberOfVolunteers > 0)
        this.editableMaximumNumberOfVolunteers--
      else if ( this.editableMaximumNumberOfVolunteers == 0 )
        this.editableMaximumNumberOfVolunteers = 0;
    },
    saveQuestEditsToCloud() {
      console.log("Save these edits! Volunteer Max is now " + this.editableMaximumNumberOfVolunteers)

      // We close the window, first of all.
      this.closeEditQuestModal()

      // We bring up a loading screen
      this.openLoadingEditQuestChangesModal()

      // Prepare to bring the entire Quest in there...
      let changedQuest = JSON.parse(JSON.stringify(this.$store.state.currentlyViewedQuest))
      changedQuest.volunteerMax = this.editableMaximumNumberOfVolunteers

      // Call the function:
      this.$store.dispatch('providerAlterSpecificQuestDetails', changedQuest)
    },
    smokeTest (){
      console.log("It works!")
    },
    openLoadingEditQuestChangesModal () {
      // I know it's wrong, but I do it anyway:
      this.$store.state.questDetailsChangesAreLoading = true;
    },
    closeSuccessfulChangesModal () {
      this.successfulChangesModalIsVisible = false;

      // I know it's wrong, but I do it anyway:
      this.$store.state.questDetailsChangesSubmittedSuccessfully = false;
    },
    returnToQuestList() {
      this.$router.push('/questList')
    },
    printPlayerInventory() {
      console.log("THIS IS OUR CURRENT INVENTORY:")

      for ( var i = 0; i < this.$store.state.inventoryContracts.length; i++ )
      {
        console.log("CONTRACT #" + i + " is a " + this.$store.state.inventoryContracts[i].DisplayName + " for Quest " + this.$store.state.inventoryContracts[i].CustomData.questID + " of Charity " + this.$store.state.inventoryContracts[i].CustomData.charityID + ". ItemInstanceId: " + this.$store.state.inventoryContracts[i].ItemInstanceId )
      }
    }
  },
  computed: {
    myComputedThing() {
      return this.$store.state.currentlyViewedQuest.volunteerMax
    },
    chariteerIsOnThisQuest() {
      for ( var i = 0; i < this.$store.state.currentlyViewedQuest.volunteerInfo.length; i++ )
      {
        if ( this.$store.state.playerProfile.masterPlayerID == this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
        {
          // console.log("We got a match, we're on this Quest!")
          return true
        }
        // console.log( this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
      }
      return false
    },
    questIsFull() {
      if ( this.$store.state.currentlyViewedQuest.volunteerInfo.length >= this.$store.state.currentlyViewedQuest.volunteerMax )
      {
        // console.log("This quest is full.")
        return true
      }
      else
      {
        // console.log("This quest has at least 1 open spot.")
        return false
      }
    },
    slotsRemaining() {

      return (this.$store.state.currentlyViewedQuest.volunteerMax - this.$store.state.currentlyViewedQuest.volunteerInfo.length)
    },
    providerHasSaveChanges() {
      // This is where I need to compare my local data with the data from the internet
      // if it's the same, it's the same and you return FALSE because there are NO CHANGES
      // otherwise it's true. So I think false is the default. If you're looping and you hit something thatd oesn't match you return immediately as TRUE

      var i = 0
      for ( i; i < this.chariteerAttendanceArray.length; i++ )
      {
        //console.log("Loop " + i)
        if ( this.chariteerAttendanceArray[i].markedAttendance != this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance )
        {
          console.log(this.chariteerAttendanceArray[i].markedAttendance + " is NOT " + this.$store.state.currentlyViewedQuest.volunteerInfo[i].actualAttendance + "!")
          
          // I've seen enough! We have something different than the last thing we got from the server.

          return true
        }
      }

      return false
    },
    isRaided () {
      if ( this.$store.state.currentlyViewedQuest.raidInfo != null && this.$store.state.currentlyViewedQuest.raidInfo.raidingGroupID != "" )
      {
        return true
      }
      else
      {
        return false
      }
    },
    combinedSurveyResultAndQuestions () {
      // This is supposed to merge the survey in state.providerSurveys that corresponds to the Survey (if any) found within the Quest.
      if ( this.$store.state.currentlyViewedQuest.survey != undefined && this.$store.state.currentlyViewedQuest.surveyResult != undefined )
      {
        console.log("We've got a survey to match!")

        var combinedCompletedSurvey = null;

        // Find if we have a Survey that matches this one in the Quest
        for (var i = 0; i < this.$store.state.providerSurveys.surveys.length; i++ )
        {
          if ( this.$store.state.providerSurveys.surveys[i].surveyID == this.$store.state.currentlyViewedQuest.survey)
          {
            console.log("It's " + this.$store.state.providerSurveys.surveys[i].surveyTitle + "!")
            combinedCompletedSurvey = this.$store.state.providerSurveys.surveys[i]
          }
        }

        if (combinedCompletedSurvey == null)
        {
          return null;
        }
        else
        {
          var j = 0;

          for ( i = 0; i < combinedCompletedSurvey.questions.length; i++ )
          {
            // For each question in this survey, find the matching answer in the surveyResult
            for ( j = 0; j < this.$store.state.currentlyViewedQuest.surveyResult.answers.length; j++ )
            {
              if ( this.$store.state.currentlyViewedQuest.surveyResult.answers[j].questionID == combinedCompletedSurvey.questions[i].questionID )
              {
                // Stuff it in and move on
                combinedCompletedSurvey.questions[i].answer = this.$store.state.currentlyViewedQuest.surveyResult.answers[j].answer;
                j = this.$store.state.currentlyViewedQuest.surveyResult.answers.length;
              }
            }
            
            // What if we couldn't find it for this Question?
          }
        }
      }

      return combinedCompletedSurvey;
    },
    questDescription1() {
      return this.$store.state.currentlyViewedQuest.description1
    },
    questDescription2() {
      return this.$store.state.currentlyViewedQuest.description2
    },
    questDescription3() {
      return this.$store.state.currentlyViewedQuest.description3
    }
  }
};
</script>

<style scoped>

#page-container {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

h1, h2, h4, p {
  margin-left: 20px;
  margin-right: 20px;
}

.special-class {
  margin-left: 20px;
  margin-right: 20px;
}

.questDescription {
  margin-left: 20px;
  margin-right: 20px;
}

.questDetails {
  background-color: white;
  border-radius: 5px;
  text-align: left;
  width: 500px;
  padding-top: 10px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 10px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.questDetailsFlexbox {
  background-color: #00000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0px auto;
}

.questDetailsGroupBox {
  box-shadow: 0 1px 5px 3px rgba(0,0,0,0.25);
  text-align: center;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  padding-top: 1vh;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  padding-bottom: 1vh;
}

.questDetailsGroupTitle {
  font-size: 125%;
  font-weight: 900;
  margin: 0px;
}

.questDetailsGroupName {
  font-size: 110%;
  font-weight: 500;
  margin: 0px;
}

.questDetailsVolunteerList {
  background-color: rgba(0, 0, 0, 0.0);
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 20px;
  box-shadow: 0 1px 5px 3px rgba(0,0,0,0.25);
}

.questDetailsVolunteerList h3 {
  text-align: center;
}

/* Volunteer Flex Box */
.questDetailsVolunteerListOther {
  display: flex;
  align-items: center;
  color: #444444;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.attendance-leftSide {
  flex: 3;
}

.questDetailsVolunteerListPlayer {
  background-color: #FFA94E;
}

.questDetailsVolunteerListEmpty {
  color: white;
  background-color: #bbbbbb9d;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 0px;
  padding: 10px;
}

.questDetailsFlexbox p {
  background-color: #00000000;

}






/* Surveys */
.questDetailsSurvey {
  background-color: rgba(76, 0, 130, 0);
  list-style-type: none;
  margin: 0;
  margin-top: 2vh;
  margin-bottom: 1vh;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 20px;
  box-shadow: 0 1px 5px 3px rgba(0,0,0,0.25);
}

.questDetailsSurvey ul {
  margin: 0px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 0px;
}

.questDetailsSurvey li {
  background-color: #ffa94e44;
  margin-top: 1vh;
  padding: 10px;
  border-radius: 10px;
}



.questDetailsSurvey h3 {
  text-align: center;
}

.questDetailsSurveyAnswer {

  list-style-type: none;
}












/* Buttons */

.editQuestButton {
  background-color: rgb(50,50,50,255);
  border: none;
  border-radius: 4px;
  margin-top: 0vh;
  margin-bottom: 1vh;
  padding: 1vh 1vh;
  width: 100%;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.editQuestButton:hover {
  background-color: rgb(100,100,100,255);
}

.editQuestButton:active {
  background-color: rgb(75,75,75,255);
  transform: scale(0.95);
}


.questDetails-saveAttendanceButton {
  background-color: #22c913;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-saveAttendanceButton:hover {
  background-color: #23da13;
}

.questDetails-saveAttendanceButton:active {
  background-color: #20a513;
  transform: scale(0.95);
}

.disabledSaveButtonStyle {
  background-color: #cccccc;
  pointer-events: none;
  opacity: 0.65;
}



.squareIncrementButton {
  background-color: rgb(50,50,50,255);
  border: none;
  border-radius: 4px;
  margin: 0;
  margin-left: 1.5vh;
  padding: 0vh;
  height: 3vw;
  width: 3vw;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.squareIncrementButton:hover {
  background-color: rgb(100,100,100,255);
}

.squareIncrementButton:active {
  background-color: rgb(75,75,75,255);
  transform: scale(0.85);
}








.questDetails-signUpButton {
  background-color: #22c913;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-signUpButton:hover {
  background-color: #23da13;
}

.questDetails-signUpButton:active {
  background-color: #20a513;
  transform: scale(0.95);
}

.questDetails-bailOutButton {
  background-color: #e44444;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetails-bailOutButton:hover {
  background-color: #f55353;
}

.questDetails-bailOutButton:active {
  background-color: #b82323;
  transform: scale(0.95);
}













/* Attendance Buttons */
.attendanceButton {
  background-color: transparent;
  padding-top: 0px;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 0px;
  margin: 0px;
  height: 32px;
  border: none;
}

.attendanceButtonImage {
  background-color: transparent;
  padding: 0px;
}








/* Modals */
.edit-quest-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}
.edit-quest-modal-content {
  background-color: #ffffff;
  border-radius: 1vw;
  margin: auto;
  margin-top: 15%;
  padding: 0px;
  padding-bottom: 5vh;
  width: 50%;
}

.edit-quest-modal-title {
  margin: 0px;
  padding-bottom: 5vh;
}

.edit-max-volunteers-row {
  display: inline;
}

.edit-max-volunteers-row-input-box {
  width: 20px;
  margin-left: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.successful-changes-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}
.successful-changes-modal-content {
  background-color: #ffffff;
  border-radius: 1vw;
  margin: auto;
  margin-top: 15%;
  padding: 0px;
  padding-bottom: 5vh;
  width: 50%;
}


.modal-top-button-row {
  display: flex;
  flex-direction: row-reverse;
  height: 5vh;
}

.modal-close {
  background-color: rgba(0, 0, 0, 0.0);
  border: none;
  text-align: center;
  font-size: 300%;
  width: 5vh;
}
















#content-wrap {
  padding-top: 50px;
  padding-bottom: 100px;    /* Footer height */
}

@media screen and (max-width: 800px) {
  #page-container {
    max-width: 100%;
  }
  #content-wrap {
    background-color: rgba(255, 47, 227, 0.5);
    max-width: 100%;
  }
  .questDetails {
    max-width: 100%;
  }
}

</style>
