<script setup>
</script>

<template>
  <router-view />

  <!-- Teleport will bring things into this div -->
  <div class="absolute" id="modalScreen" name="modalScreen" />
</template>

<style>
  @import url('./index.css');
</style>
