<template>
  <div class="bg-cq-orange text-cq-gray text-center items-center">
    <MasterLayout>
      <!-- This ad needs to be here because a local business is paying me! -->
      <FDFullWidthBanner />

      <!-- If no guilds are available, show a message to the user -->
      <div
        v-if="currentGuilds.length === 0"
        class="border-4 border-solid border-cq-gray m-10 rounded-2xl py-5"
      >
        <h2>
          It looks like you have not joined any groups yet{{
            $store.state.playerProfile.firstName
              ? ", " + $store.state.playerProfile.firstName
              : ""
          }}!
          <br />
          Take a look at the list below to browse available Guilds.
        </h2>
      </div>

      <!-- If Guilds are available, show the guilds -->
      <div v-else class="m-5">
        <h1>Your Guilds</h1>
        <div
          class="space-x-10 overflow-x-auto w-9/10 flex flex-nowrap overflow-hidden mx-auto"
        >
          <GuildCard
            v-for="guild in currentGuilds"
            :key="guild.id"
            :guild="guild"
            @click="openGuildDetails(guild)"
            class="flex-shrink-0"
          />
        </div>
      </div>

      <div class="w-4/5 mx-auto">
        <!-- Introduction to the search text -->
        <div class="text-left">
          <h1 v-if="this.$store.state.playerProfile.chariteerExistsInDatabase">
            Search Available Guilds
          </h1>
          <h1 v-else>
            <a v-on:click="goToLogin" class="underline cursor-pointer"
              >Login to Search Available Guilds</a
            >
          </h1>
          <p class="text-xl">
            Guilds are volunteer groups on Charity Quest. Volunteer events can
            be reserved for your group, and your group is automatically entered
            into any volunteer contest that you are eligible to enter.
          </p>
        </div>

        <!-- Search and results -->
        <div v-if="this.$store.state.playerProfile.chariteerExistsInDatabase">
          <input
            id="guildSearch"
            v-model="guildSearch"
            type="text"
            placeholder="Filter guilds as you type..."
            class="w-full border-none rounded-md p-3 mb-10 text-md"
          />

          <!-- Guild Results -->
          <div
            v-show="!this.loading"
            name="list"
            tag="div"
            class="flex flex-wrap justify-center"
          >
            <GuildCard
              v-for="guild in filteredGuilds"
              :key="guild.id"
              :guild="guild"
              @click="openGuildDetails(guild)"
              class="w-full mx-auto"
            />

            <!-- Fallback for search -->
            <div
              v-show="guildSearch && filteredGuilds.length === 0"
              class="text-center"
              key="item-2"
            >
              <h3>No guilds found matching your search</h3>
            </div>
          </div>

          <!-- Pagination -->
          <div class="flex items-center justify-center" key="item-1">
            <button
              @click="goToPreviousPage"
              class="p-2 bg-white border-none rounded-lg drop-shadow-md hover:cursor-pointer hover:bg-gray-100 hover:scale-105 hover:drop-shadow-lg w-24 h-10"
              :style="{
                opacity: this.pageNumber > 1 ? '1' : '0',
                'pointer-events': this.pageNumber > 1 ? 'auto' : 'none'
              }"
            >
              &lt; Previous
            </button>
            <p
              class=" p-2 mx-5 rounded-xl h-8 w-8 bg-white border-none text-center drop-shadow-md"
            >
              {{ pageNumber }}
            </p>
            <button
              @click="goToNextPage"
              class="p-2 bg-white border-none rounded-lg drop-shadow-md hover:cursor-pointer hover:bg-gray-100 hover:scale-105 hover:drop-shadow-lg w-24 h-10"
              :style="{
                opacity:
                  this.pageNumber < Math.ceil(this.totalGuilds / 8) ? '1' : '0',
                'pointer-events':
                  this.pageNumber < Math.ceil(this.totalGuilds / 8)
                    ? 'auto'
                    : 'none'
              }"
            >
              Next &gt;
            </button>
          </div>
        </div>
      </div>

      <!-- Modal for the guild details -->
      <GuildDetailsModal
        :showModal="showGuildModal"
        :data="currentGuild || {}"
        @close="closeModal"
      >
        <template v-slot:footer>
          <div class="flex justify-center">
            <button
              class="bg-red-300 text-xl rounded-xl shadow-lg px-5 py-2 border-none hover:cursor-pointer hover:bg-red-400 transition-colors duration-300 ease-in-out w-40"
              @click="closeModal"
            >
              Close
            </button>
          </div>
        </template>
      </GuildDetailsModal>
    </MasterLayout>

    <!-- "Login" (Modal)-->
    <LoginModal v-if="loginModalIsVisible" :close="handleCloseLoginModal" />
  </div>
</template>

<script>
// Guilds is a container that holds the modules for showing our current Guild memberships, and
// browsing the Guild list for other Guilds to join.
import MasterLayout from "@/components/MasterLayout.vue";
import LoginModal from "@/components/modals/LoginModal.vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useCookies } from "vue3-cookies";
import GuildCard from "../components/GuildCard.vue";
import FDFullWidthBanner from "../components/advertisements/FDFullWidthBanner.vue";
import GuildDetailsModal from "../components/modals/GuildDetailsModal.vue";

export default {
  name: "Guilds",
  components: {
    MasterLayout,
    FDFullWidthBanner,
    GuildCard,
    GuildDetailsModal,
    LoginModal
  },
  data() {
    return {
      guildSearch: "",
      pageNumber: 1,
      totalGuilds: 0,
      isPaginating: false,
      currentGuild: null,
      showGuildModal: false,
      loginModalIsVisible: false
    };
  },
  setup() {
    const { result, loading, error, refetch } = useQuery(gql`
      query GetAllGuilds {
        guilds {
          id
          name
          class
          description
        }
      }
    `);
    const { cookies } = useCookies();
    return {
      allQueriedGuilds: result || null,
      loading,
      error,
      refetch,
      cookies
    };
  },
  computed: {
    currentGuilds() {
      return this.$store.state.groupMemberships.map(group => {
        return {
          name: group.GroupName,
          id: group.Group.Id
        };
      });
    },
    filteredGuilds() {
      let filteredGuilds = this.filterGuilds(
        this.allQueriedGuilds?.guilds || []
      );
      if (this.guildSearch) {
        filteredGuilds = filteredGuilds?.filter(guild =>
          guild.name.toLowerCase().includes(this.guildSearch.toLowerCase())
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pageNumber = 1;
      }
      return filteredGuilds
        ?.sort((a, b) => a.name.localeCompare(b.name))
        ?.slice((this.pageNumber - 1) * 8, this.pageNumber * 8);
    }
  },
  methods: {
    filterGuilds(guilds) {
      let guildMembershipIds = this.currentGuilds.map(guild => guild.id);
      return guilds.filter(guild => !guildMembershipIds.includes(guild.id));
    },
    openGuildDetails(guild) {
      this.currentGuild = guild;
      this.showGuildModal = true;
    },
    closeModal() {
      this.showGuildModal = false;
    },
    handleCloseLoginModal() {
      this.loginModalIsVisible = false;
    },
    async goToPreviousPage() {
      if (this.pageNumber > 1) {
        this.isPaginating = true;
        this.pageNumber--;
        await this.$nextTick();
        this.isLoading = false;
      }
    },
    async goToNextPage() {
      if (this.pageNumber < Math.ceil(this.totalGuilds / 8)) {
        this.isPaginating = true;
        this.pageNumber++;
        await this.$nextTick();
        this.isLoading = false;
      }
    },
    goToLogin() {
      this.cookies.set(
        "returnTo",
        { path: this.$route.path, query: this.$route.query },
        { expires: 1 }
      );
      this.loginModalIsVisible = true;
      // this.$router.push("/login");
      return null;
    }
  },
  watch: {
    allQueriedGuilds: {
      handler: function(newVal) {
        if (newVal) this.totalGuilds = this.filterGuilds(newVal.guilds).length;
      },
      immediate: true
    }
  }
};
</script>

<style>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 70%;
  padding: 20px;
  position: relative;
  background: #fff;
}
</style>
