<template>
  <div class="splash-page">
    <TopNav/>
    <div class="bg-cq-orange-light">
      <div class="w-2/3 mx-auto pt-5 pb-10">
        <p class="text-black text-center font-bold text-4xl pb-5">Pricing</p>
        <p class="text-center">Charity Quest is a free volunteer management platform for local charities in your area. Originally "Hoboken Charity Quest", the network has now grown to 20+ organizations, 9000+ volunteers, and is available all across Hudson County, NJ. Recently we expanded to Staten Island and the Bronx, NY. To keep this network in operation, and 100% free for local charities, we humbly ask volunteers to support the network by becoming a subscriber.</p>
        <br>
        <p class="text-center">Your subscription is not a donation - it's a way to unlock awesome perks and prizes that are reserved for Very Important Volunteers (V.I.V.s). At Charity Quest, we're committed to creating an excellent volunteer experience all across the Tri-State Area. Thank you so much for your support!</p>
      </div>
      <!-- For testing stripe 42424242422424224 and any valid expiration date in future -->

      <stripe-pricing-table pricing-table-id="prctbl_1OB1o5B7fbVjG4pW5arpvUGI"
        publishable-key="pk_live_SjLJjGfP8tSDnMaJ3IvsmXg2">
      </stripe-pricing-table>

      <div class="w-2/3 mx-auto pt-5 pb-10">
        <p class="text-black text-center font-bold text-4xl pb-5">Questions?</p>
        <p class="text-center">Feel free to reach out to me directly by emailing frank@charityquest.io if you have any questions about the current subscription plan, and the roadmap of features we have planned out..</p>
        <br>
      </div>
    </div>



    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Subscribe",
  components: {
    TopNav,
    Footer
  },
  mounted() {
      let stripeScript = document.createElement('script')
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/pricing-table.js')
      document.head.appendChild(stripeScript)
    },
};
</script>

<style scoped>
.splash-page {
  background-color: rgba(0, 128, 0, 0.0);
  height: 100%;
  margin: 0px;
}

p {
  margin: 0px;
}

</style>