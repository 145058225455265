<template>
  <div id="page-container">
    <TopNav/>
    <div id="content-wrap">
      <div class="questDetails">
        <h1>{{ $store.state.currentlyViewedQuest.eventTitle }}</h1>
        
        <h2>{{ $store.state.currentlyViewedQuest.providerName }}</h2>
        <p><i>Application</i></p>

        <p v-if="$store.state.currentlyViewedQuest.date != null">{{ new Date($store.state.currentlyViewedQuest.date).toDateString() + " from " + $store.state.currentlyViewedQuest.startTime + " to " + $store.state.currentlyViewedQuest.endTime }}</p>
        
        <!--
        <div class="special-class" markdown="1">
          {{ $store.state.currentlyViewedQuest.description1 + " " + $store.state.currentlyViewedQuest.description2 + " " + $store.state.currentlyViewedQuest.description3 }}
        </div>
        -->

        <!-- *NEW* Quest Description that allows for Markdown -->
        <h4>Description:</h4>
        <div v-html="questDescription1" class="questDescription"></div>
        <div v-html="questDescription2" class="questDescription"></div>
        <div v-html="questDescription3" class="questDescription"></div>
        <br>
        
        <!-- IMPORTANT LINKS? -->
        <template v-if="$store.state.currentlyViewedQuest.applicationAction.applicationPurpose == 'requestToJoinGroup'">
          <h4>Important Links</h4>
          <ul class="importantList">
            <li v-for="(link, index) in $store.state.currentlyViewedQuest.applicationLinks" class="importantLink" v-on:click="clickApplicationLink(index)" :key=index>{{ link.applicationLinkTitle }}</li>
          </ul>
        </template>
                

        <!-- Which Button should we show? -->
        <!-- For a Group Application, show the Submit Application button which goes to "submitApplication()" -->
        <!-- For an External Link, show XYZ -->
        <div class="questDetailsFlexbox">
                  
          <!-- Submit Application Button --->
          <button v-if="$store.state.currentlyViewedQuest.applicationAction.applicationPurpose == 'requestToJoinGroup'" class="submitApplicationButton" v-on:click="submitApplication">
            Submit Application
          </button>

          <!-- External Link Button --->
          <button v-if="$store.state.currentlyViewedQuest.applicationAction.applicationPurpose == 'externalURL'" class="submitApplicationButton" v-on:click="goToExternalURL">
            Go to Application
          </button>

        </div>

        <!--
        <button class="questDetails-backButton" v-on:click="returnToQuestList">
            Go back to QuestList
          </button>
          -->
      </div>
    </div>
    <Footer/>

    <!-- Loading (Modal) -->
    <div class="loading-modal" v-if="loadingModalIsVisible">
      <!-- Modal content -->
      <div class="loading-modal-content">
        <h1>Submitting application . . .</h1>
        <h3><i>Please do not close this tab.</i></h3>
      </div>
    </div>

    <!-- Submit Application Success (Modal) -->
    <div class="submitApplicationSuccess-modal" v-if="submitApplicationSuccessModalIsVisible">
      <!-- Modal content -->
      <div class="submitApplicationSuccess-modal-content">
        <h1>Application Submitted!</h1>
        <h3>This charity will review your application and let you know whether or not you've been accepted.</h3>
        <button class="submitApplicationButton" v-on:click="closeSubmitApplicationSuccessModal">Great!</button>
      </div>
    </div>

    <!-- Error (Modal) -->
    <div class="submitApplicationSuccess-modal" v-if="submitApplicationErrorModalIsVisible">
      <!-- Modal content -->
      <div class="submitApplicationSuccess-modal-content">
        <h1>{{ errorModalTitleText }}</h1>
        <h3>{{ errorModalDescriptionText }}</h3>
        <button class="closeErrorModalButton" v-on:click="closeSubmitApplicationErrorModal">OK.</button>
      </div>
    </div>
    
  </div>
</template>

<script>
// QuestDetails shows you everything about the Quest.
// Intended to be accessed via QuestList OR your ActiveQuests.
import TopNav from "@/components/TopNav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "QuestDetails",
  data() {
    return {
      errorModalTitleText: 'Wait!',
      errorModalDescriptionText: 'There was a problem submitting your application.'
    }
  },
  components: {
    TopNav,
    Footer
  },
  props: ['id'],
  created() {
    // This is the sort of thing that ought never be done, my child
    this.$store.state.questApplicationIsLoading = false
    this.$store.state.questApplicationSubmittedSuccessfully = false
    this.$store.state.questApplicationErrorCode = -1

    console.log("Fetching an event for " + this.id)
    this.$store.dispatch('fetchEvent', this.id)

    // this.printPlayerInventory()
  },
  methods: {
    clickApplicationLink(index) {
      console.log("Clicked the index " + index)
      if ( this.$store.state.currentlyViewedQuest.applicationLinks[index].applicationLinkURL != null)
        window.open(this.$store.state.currentlyViewedQuest.applicationLinks[index].applicationLinkURL);
      
      else
        console.log("There was no URL for this button.")
    },
    submitApplication() {
      console.log("Chariteer clicked the Submit Application button!")

      // You're not supposed to do what I do right here:
      this.$store.state.questApplicationIsLoading = true

      // Tracking
      // var tagsObj = this.$store.state.currentlyViewedQuest
      // tagsObj.messageType = "Chariteer Clicks Volunteer Button"
      // this.$store.dispatch('clickTrackLogForAmazon', tagsObj)

      // Execution
      this.$store.dispatch('submitApplication')
    },
    closeSubmitApplicationSuccessModal() {
      // Never do this:
      this.$store.state.questApplicationSubmittedSuccessfully = false
    },
    closeSubmitApplicationErrorModal() {
      // Never do this:
      this.$store.state.questApplicationErrorCode = -1
    },
    changeErrorModalText() {
      console.log("HUEL!!!!")

      if ( this.$store.state.questApplicationErrorCode == -1 )
      {
        this.errorModalDescriptionText = "There was a problem submitting your application."
      }
      else if ( this.$store.state.questApplicationErrorCode == 1359 )
      {
        this.errorModalDescriptionText = "According to our records, you already belong to the Group for " + this.$store.state.currentlyViewedQuest.providerName + ". Someone must have already reviewed and accepted your application. There's no need to submit anything else, you can leave this page."
      }
      else
      {
        this.errorModalDescriptionText = "An error occurred submitting your application, and I have no idea what went wrong. I'm really sorry."
      }
    },
    goToExternalURL () {
      window.open(this.$store.state.currentlyViewedQuest.applicationLinks[0].applicationLinkURL);
    },
    returnToQuestList() {
      this.$router.push('/questList')
    },
    printPlayerInventory() {
      console.log("THIS IS OUR CURRENT INVENTORY:")

      for ( var i = 0; i < this.$store.state.inventoryContracts.length; i++ )
      {
        console.log("CONTRACT #" + i + " is a " + this.$store.state.inventoryContracts[i].DisplayName + " for Quest " + this.$store.state.inventoryContracts[i].CustomData.questID + " of Charity " + this.$store.state.inventoryContracts[i].CustomData.charityID + ". ItemInstanceId: " + this.$store.state.inventoryContracts[i].ItemInstanceId )
      }
    },
    openPrimeCycleWebsite()
    {
      var tagsObj = {
        messageType: "Chariteer Clicks Sponsor's Banner In Quest"
      }
      this.$store.dispatch('clickTrackLogForAmazon', tagsObj)
      window.open("https://www.primecycle.com");
    }
  },
  computed: {
    myComputedThing() {
      return this.$store.state.currentlyViewedQuest.volunteerMax
    },
    loadingModalIsVisible() {
      return this.$store.state.questApplicationIsLoading
    },
    submitApplicationSuccessModalIsVisible() {
      return this.$store.state.questApplicationSubmittedSuccessfully
    },
    submitApplicationErrorModalIsVisible() {
      if ( this.$store.state.questApplicationErrorCode == -1 )
      {
        console.log("No error code, no pop-up.")
        return false
      }
      else if ( this.$store.state.questApplicationErrorCode == 1359 )
      {
        console.log("Error 1359")
        
        this.changeErrorModalText()
        
        return true
      }
      else
      {
        return false
      }
    },
    chariteerIsOnThisQuest() {
      for ( var i = 0; i < this.$store.state.currentlyViewedQuest.volunteerInfo.length; i++ )
      {
        if ( this.$store.state.playerProfile.masterPlayerID == this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
        {
          // console.log("We got a match, we're on this Quest!")
          return true
        }
        // console.log( this.$store.state.currentlyViewedQuest.volunteerInfo[i].userID )
      }
      return false
    },
    questIsFull() {
      if ( this.$store.state.currentlyViewedQuest.volunteerInfo.length >= this.$store.state.currentlyViewedQuest.volunteerMax )
      {
        // console.log("This quest is full.")
        return true
      }
      else
      {
        // console.log("This quest has at least 1 open spot.")
        return false
      }
    },
    slotsRemaining() {

      return (this.$store.state.currentlyViewedQuest.volunteerMax - this.$store.state.currentlyViewedQuest.volunteerInfo.length)
    },
    questDescription1() {
      return this.$store.state.currentlyViewedQuest.description1
    },
    questDescription2() {
      return this.$store.state.currentlyViewedQuest.description2
    },
    questDescription3() {
      return this.$store.state.currentlyViewedQuest.description3
    }
  }
};
</script>

<style scoped>

h4 {
  text-align: left;
}

.questDetails {
  background-color: white;
  border-radius: 5px;
  text-align: left;
  width: 500px;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 25px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
}

.importantList {
  margin: 0px;
  padding: 0px;
}

.importantLink {
  background-color: #53A9D4;
  border: none;
  border-radius: 4px;
  margin: 10px;
  padding: 10px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  
  transition-duration: 0.15s;
  cursor: pointer;
}

.questDetailsFlexbox {
  background-color: #00000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0px auto;
}

.questDetailsFlexbox p {
  background-color: #00000000;
}

.questDescription {
  text-align: left;
}

.submitApplicationButton {
  background-color: #22c913;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}
.submitApplicationButton:hover {
  background-color: #23da13;
}
.submitApplicationButton:active {
  background-color: #20a513;
  transform: scale(0.95);
}

.closeErrorModalButton {
  background-color: #c91313;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 20px 50px;
  outline: none;

  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.15s;
  cursor: pointer;
}
.closeErrorModalButton:hover {
  background-color: #d41b1b;
}
.closeErrorModalButton:active {
  background-color: #a10e0e;
  transform: scale(0.95);
}



/* Sponsors */
.heroSponsorButton {
  background-color: rgba(0, 0, 0, 0.0);
  width: 500px;
  border-width: 0px;
  margin: 0px;
  margin-bottom: 40px;
  padding: 0px;
  cursor: pointer;
}

.heroSponsorImage {
  width: 500px;
}

#page-container {
  background-color: #FFA94E;
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-top: 50px;
  padding-bottom: 100px;    /* Footer height */
}



/* Modals */
.loading-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #FFA94E; /* Fallback color */
  background-color: #FFA94EA9; /* Orange w/ opacity */
}
.loading-modal-content {
  background-color: #ffffff;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 1px 25px 3px rgba(0,0,0,0.25);
}

.submitApplicationSuccess-modal {
  /* display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #FFA94E; /* Fallback color */
  background-color: #FFA94EA9; /* Orange w/ opacity */
}
.submitApplicationSuccess-modal-content {
  background-color: #ffffff;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 1px 25px 3px rgba(0,0,0,0.25);
}

</style>
