<template>
  <div class="home">
    <TopNav />
    <div class="flex flex-row bg-cq-orange-light">
      <!-- Stamps needs to know which Provider we're looking at currently -->
      <Stamps
        v-model:selectedManagedProviderId="
          this.$store.state.currentlySelectedManagedProviderGroup
            .ManagedProviderId
        "
        v-if="true"
      />
      <div id="Calendar and Controls Together" class="grow">
        <!-- <select @change="e => onChange(e)" v-show="dropdownHasMultipleChoices">
          <option
            v-for="groupInfo in this.$store.state
              .managedProviderGroupMemberships"
            :key="groupInfo.ManagedProviderId"
            :value="groupInfo.ManagedProviderId"
          >
            {{ groupInfo.ManagedProviderName }}
          </option>
        </select> -->
        <!-- The Calendar needs to know which Provider we're looking at currently -->
        <FDCalendar
          v-model:selectedManagedProviderId="
            this.$store.state.currentlySelectedManagedProviderGroup
              .ManagedProviderId
          "
          v-model:managedProviderMemberships="this.managedProviderMemberships"
          ref="child"
          class="mt-5"
        />
      </div>
    </div>
    <!-- <SecretCalendarComponent :managedProviderMemberships="this.$store.state.managedProviderGroupMemberships"/> -->
    <Footer v-show="false" />
  </div>
</template>

<script>
import TopNav from "@/components/TopNav.vue";
import Stamps from "@/components/calendar/Stamps.vue";
import FDCalendar from "@/components/calendar/FDCalendar.vue";
// import SecretCalendarComponent from "@/components/SecretCalendarComponent.vue"
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    TopNav,
    Stamps,
    FDCalendar,
    // SecretCalendarComponent,
    Footer
  },
  data() {
    return {
      selectedManagedProviderId:
        this.$store.state.managedProviderGroupMemberships?.find(item => true)
          ?.ManagedProviderId || "",
      managedProviderMemberships: this.$store.state
        .managedProviderGroupMemberships
    };
  },
  methods: {
    onChange(e) {
      console.log(e.target.value);

      // const entireObject = USE ARRAY.FIND TO GET THE GROUPINFO OBJECT BASED ON THE PROVIDER ID
      this.selectedManagedProviderId = e.target.value;
      console.log("Do we have access to the right child?");
      console.log(this.$refs.child);
      console.log(this.$refs.child.refetch);
      console.log("We're refetching with dates, too:");
      console.log(this.$refs.child.calendarDisplayStartDateTime);

      // Reload the Calendar with the new Provider ID
      this.$refs.child.refetch({
        GQI: {
          specificProvider: e.target.value,
          dateRangeBeginning: this.$refs.child.calendarDisplayStartDateTime,
          dateRangeEnding: this.$refs.child.calendarDisplayEndDateTime
          // isUpcoming?
          // isPublished?
        }
      });

      // Reload the Calendar with the new Provider ID
      // You can do this once the Stamps are no longer hardcoded, and you are somehow querying the database
      // this.$refs.stampsChild.refetch({
      //     "GQI": {
      //         "specificProvider": e.target.value,
      //         // isUpcoming?
      //         // isPublished?
      //       }
      //   });
    }
  },
  computed: {
    dropdownHasMultipleChoices() {
      if (this.managedProviderMemberships.length > 1) return true;
      else return false;
    }
  }
};
</script>
